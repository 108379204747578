<template>
  <v-btn
    @click="route ? $router.push(route) : $router.go(-1)"
    color="#9e9e9e3d"
    class="mr-3 rounded"
    outlined
    small
    icon
  >
    <v-icon color="accent" small>mdi-arrow-left</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    route: Object
  }
}
</script>